.topbar {

  width: 100%;

  top: 0;
  left: 0;

  border-bottom: 1px solid rgba(black, .05);
  background: white;

  transition: all .5s !important;
  z-index: 9999;

  &>.container {
    transition: .5s all;
    height: rem-calc($topbar-height);

    @include breakpoint(small down) {
      padding-right: rem-calc(4);
    }
  }

  &.fixed {
    position: fixed;
  }

  &.sticky {
    @include mk-shadow(1);
    border-bottom: none;
  }

  .logo {
    height: 90%;
    width: auto;
  }
}

.topbar.glass:not(.sticky) {
  background: transparent;
}


.home .topbar.glass:not(.sticky) {
  background: transparent;

  & .nav__item,
  & .openMenu .btn {
    color: white;

    & a,
    button {
      color: inherit;
    }
  }

  & .logo {
    filter: brightness(0) invert(1);
  }
}




.topnav {
  @include mk-flexbox(row, $align:center);
  height: 100%;
  flex: 1;

  // & .nav__item:not(.openMenu) {
  //   @include breakpoint(sm down) {
  //     display: none;
  //   }
  // }

  & .nav__item.openMenu {
    @include hide-for(md)
  }
}

.nav {
  // @include mk-flexbox(row, $justify:space-around, $align:center);
  @extend .grid__container,
  .spacing-16;
  margin: 0;
  align-items: center;
}

.nav__item {
  @extend .grid__item;

  & a,
  button {
    font-weight: bold;
  }

  &.active {
    opacity: .5;

    &:before {
      content: '';
      position: absolute;
      height: rem-calc(3);
      background-color: get-color(primary);
      width: 100%;
      bottom: 0;
      left: 0;
    }
  }

  @include breakpoint(xs only) {
    font-size: rem-calc(14);
  }
}

.nav__sub-menu {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.mega-menu {
  display: none;
  width: 100%;

  &.showing {
    display: block;
  }
}