$gutter: 25;

.panel {
  @extend .card;

  &--transparent {
    border-radius: none;
    box-shadow: none !important;
    background: transparent !important;

    & .panel__summary {

      // min-height: rem-calc(48) !important;
      @include breakpoint(xs) {
        padding: 0;
      }

      // border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    & .panel__summary>div {
      margin: 0 !important;

      @include breakpoint(xs) {
        padding: 0;
      }
    }

    // & .panel__body {
    //   @include breakpoint(xs) {
    //     padding-left: 0;
    //     padding-right: 0;
    //   }
    // }

    // & .panel__header {
    //   @include breakpoint(xs) {
    //     margin: 0;
    //   }
    // }
  }
}

.panel__summary {
  @extend .card__header;
  // font-size: 95%;
  // color: $text-primary;
  // font-weight: 400;
  // padding-left: 0 !important;
}

.panel__header {
  @extend .card__header;

  & .icon {
    margin-right: rem-calc(10);
    line-height: 1;
  }

  & .panel__title,
  & .panel__subtitle {
    margin-bottom: 0;
  }

  &--inner {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: rem-calc(24);
  }
}

.panel__title {
  font-weight: 600;
  color: black;
  font-size: rem-calc(15);
}

.panel__icon {
  & svg {
    vertical-align: middle;
  }
}

.panel__subtitle {
  letter-spacing: rem-calc(0.5);
  font-weight: bold;
  color: $medium-gray;
  font-size: rem-calc(16);
  margin-bottom: rem-calc(24);

  &--collapse {
    margin-bottom: 0;
  }
}

.panel__body {
  @extend .card__body;
}

// .panel__header + .panel__body {
//   padding-top: rem-calc($gutter/2);
// }
.panel__section {
  @extend .card__section;
}

.panel__media-wrapper {
  @extend .card__media-wrapper;
}

.panel.media-panel {

  .media-wrapper {
    display: block !important;
    border: none !important;
    margin: 0 !important;
  }

  .form-control__label {
    display: none;
  }
}