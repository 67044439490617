$card-container-bg: rgb(32, 32, 32);
$gutter: 24;
$base-radius: rem-calc(16);

.card {
  @include mk-flexbox();
  @include mk-shadow(4, 'difuse');
  font-size: 95%;
  color: $text-primary;
  font-weight: 400;
  font-size: rem-calc(15);

  border-radius: $base-radius !important;
  background-color: white;

  transition: box-shadow .3s;

  &:hover {
    @include mk-shadow(6, 'difuse');
  }

  & .card__text:last-child {
    margin-bottom: 0;
  }

  &--btn,
  &--btn * {
    cursor: pointer;
  }

  &--wide {
    flex-direction: row;
    justify-content: flex-start;

    & .card__media-wrapper {
      width: 30%;
    }

    & .card__media-wrapper+.card__body {
      width: 70%;
      padding: rem-calc($gutter/2);
    }
  }

  .radius {
    &--top-left {
      border-top-left-radius: $base-radius;
    }
  }


}


a.card {
  @include mk-elevation(4, 'soft');
}

.card__section {
  border-radius: $base-radius;
}

.card__section-title {
  font-size: rem-calc(17);
  font-weight: 700;
}

.card__section:first-child:not(:last-child) {
  border-radius: $base-radius $base-radius 0 0;
}

.card__section:last-child:not(:first-child) {
  border-radius: 0 0 $base-radius $base-radius;
}

.card--wide {
  .card__section:first-child {
    border-radius: $base-radius 0 0 $base-radius;
  }

  .card__section:last-child {
    border-radius: 0 $base-radius $base-radius 0;
  }
}


.card__body,
.card__footer {
  padding: rem-calc($gutter);
  z-index: 1;
}

.card--small {
  & .card__header {
    margin: 0;
    padding: rem-calc($gutter/3);
  }

  & .card__body,
  & .card__footer {
    padding: rem-calc($gutter/1.5);
  }
}

.card__body {
  flex: 1 1 auto;
}

.card__header {
  @include mk-flexbox(row);
  width: auto;
  margin: 0 rem-calc($gutter);
  padding: rem-calc($gutter) 0;

  &>div {
    flex: 1;
  }

  &:first-child {
    border-radius: rem-calc(5) rem-calc(5) 0 0;
  }

  & .avatar {
    justify-self: flex-start;
    padding: rem-calc($gutter/3);

    & .avatar__body {
      @extend .media-wrapper,
      .media-wrapper--circle;
      width: rem-calc(44);
    }
  }

  & .btns {
    justify-self: flex-end;
    margin-left: auto;
    margin-right: rem-calc(-$gutter/3);
    margin-top: rem-calc(-$gutter/3);
    text-align: right;
  }

  & .content {
    padding: rem-calc($gutter/3);
  }

  & .card__title {
    margin: 0;
  }
}

.card__header+.card__body,
.card--small .card__header+.card__body-wrapper .card__body,
.card__body+.card__body {
  padding-top: 0;
}

.card__header--expand {
  cursor: pointer;
  // & + .card__body,
  // & + .card__body-wrapper {
  //   padding-top: rem-calc($gutter/2);
  //   border-top: 1px solid rgba(black, 0.03);
  // }
}

.card__footer {
  padding-top: rem-calc(10);

  &:last-child {
    border-radius: 0 0 rem-calc(5) rem-calc(5);
  }
}

.card__action-btns {
  @include mk-flexbox(row wrap, $justify: space-between, $align: center);
  width: auto;
  padding: 0 rem-calc($gutter/5) rem-calc($gutter/5);
  text-align: center;

  .action-btn {
    margin: rem-calc($gutter/5);
  }
}

.card__title {
  position: relative;
  margin-bottom: rem-calc(16);

  font-size: rem-calc(18);
  line-height: 1.8;
  letter-spacing: rem-calc(0.5);
  font-weight: 600;
  color: $text-emphasis;

  &--upper-light {
    text-transform: uppercase;
    font-size: rem-calc(25);
    font-weight: 300;
  }
}

.card--small .card__title {
  font-size: rem-calc(15);
}

.card__text {
  &--emphasis {
    color: $text-emphasis;
  }

  & span {
    font-size: inherit !important;
    font-family: inherit !important;
  }

  &--date {
    white-space: nowrap;
  }
}

.card--small .card__text {
  font-size: 90%;
}

small.card__text {
  font-size: 82%;
}

.card__divisor {
  position: relative;
  display: block;
  margin: rem-calc(16) 0;
  border-bottom: 1px solid rgba(black, .075);
}

.card__spacer {
  position: relative;
  display: block;
  margin: 0 0 rem-calc($gutter) 0;
}

.card__text+.card__divisor {
  margin-top: 0;
}

/* 
MEDIA WRAPPER 
When content is inside card__media-wrapper
*/
.card__media-wrapper {
  @extend .media-wrapper;
  color: white;
  font-size: 115%;
  border-radius: inherit;

  &--square {
    @extend .media-wrapper--square;
  }

  &--portrait {
    @extend .media-wrapper--portrait;
  }

  &--wide {
    @extend .media-wrapper--wide;
  }

  &--circle {
    @extend .media-wrapper--circle;
  }

  & .card__title {
    margin-bottom: rem-calc(5);
    text-align: left;
    color: inherit;
    text-shadow: 0 0 35px rgba(black, 0.8);
    font-weight: bold;
  }

  & .card__text {
    font-weight: 600;
  }

  & .icon {
    opacity: 1;
  }

  & .card__text,
  & .caption {
    color: inherit;
  }

  & .card__header {
    justify-content: center;
  }

  & .card__body {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: rem-calc(15);
    // top: auto;
    // height: 50%;

    text-align: left;
    color: white;

    transition: all 0.3s;

    background: -moz-linear-gradient(top,
        rgba($card-container-bg, 0) 0%,
        rgba($card-container-bg, 0.9) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top,
        rgba($card-container-bg, 0) 0%,
        rgba($card-container-bg, 0.9) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,
        rgba($card-container-bg, 0) 0%,
        rgba($card-container-bg, 0.9) 100%);
  }

  & .card__action-btns {
    padding: 0;
  }

  & .card__btn {
    border-color: white;
    color: white;
    background-color: rgba(white, 0.1);

    &:hover {
      color: get-color(primary);
      background-color: white;
      border-color: white;
      box-shadow: none;
    }
  }
}