html {
  min-height: 100%;
}

body {
  margin: 0;
  font-family: $body-font-family;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
  line-height: 1.4;
  background-color: $body-background;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


html,
body {
  height: 100%;
}

iframe {
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

fieldset {
  border: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  font-family: $body-font-family;
}

small {
  transition: 0.2s all;
}

/* Charts */
// tspan {

// }
// .chart__area .recharts-area-area {
//   fill: get-color(info);
//   stroke: darken(get-color(info), 30);
// }

// path.recharts-curve.recharts-line-curve {
//   stroke: get-color(alert);
// }
// .recharts-cartesian-axis {
//   opacity: 0.35;
// }
// g.recharts-layer.recharts-cartesian-axis-tick {
//   font-size: rem-calc(12);
// }
label,
label * {
  font-family: $body-font-family;
  font-size: inherit;
  color: inherit;
}

a,
a:focus,
a:link,
a:visited,
a:active {
  text-decoration: none;
  outline: none;
}

// a:not([class*="btn"]),
// a:not([class*="btn"]):focus,
// a:not([class*="btn"]):visited,
// a:not([class*="btn"]):active {
//   color: get-color(primary);
// }

main {
  flex: 1;
  position: relative;
}

.maintenance {
  & main {
    @include mk-flexbox($justify:center)
  }
}

.main-wrapper {
  @include mk-flexbox($justify: space-between);

  min-height: 100%;
  height: 100%;

}

.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transition: .3s all;
  background-color: black;
}

.modal-blur {
  filter: blur(0);
  transition: .3s filter;
}

.modal-active {
  .modal-blur {
    filter: blur(rem-calc(2));
  }

  .modal-bg {
    opacity: .4;
    visibility: visible;
  }
}


.container {
  @include mk-flexbox(row, $justify: space-between, $align:center, $grid:true);
  margin: 0 auto;

  &.fullWidth {
    max-width: none;
    width: 100%;
  }
}


// header.fixed+.main-wrapper {
//   padding-top: rem-calc(80);
// }



p {
  text-align: inherit;
  margin-top: 0;
  margin-bottom: rem-calc(16);
  font-size: $global-font-size;

  &.big {
    font-size: 120%;
  }

  &.bigger {
    font-size: 140%;
  }

  &.small {
    margin-bottom: rem-calc(10);
  }

  &.smaller {
    margin-bottom: rem-calc(8);
  }

  &:only-child,
  &:last-child {
    margin-bottom: 0;
  }
}

.medium {
  font-size: 95% !important;
}

.small {
  font-size: 90% !important;
}

.smaller {
  font-size: 80% !important;
}

ul {
  padding: 0;
  margin: 0;

  li {
    margin-left: rem-calc(20);
  }
}

*:focus {
  outline: none;
}

.text-mutted {
  color: $medium-gray;
}

.xs-collumn-reverse {
  @include breakpoint(xs only) {
    flex-direction: column-reverse;
  }
}

.pointer {
  cursor: pointer;
}

// @include breakpoint(retina) {
//   @include breakpoint(xs only) {
//     html {
//       font-size: 102%;
//     }
//   }

//   @include breakpoint(xl) {
//     html {
//       font-size: 102%;
//     }
//   }
// }




.float-label~.django-ckeditor-widget {
  margin-top: rem-calc(24);
}

.django-ckeditor-widget {
  min-width: 100% !important;
}

/* --------------------------------------------------
Wrapper
-------
Override any wrapper
-------------------------------------------------- */
.wrapper {
  transition: 0.2s all;
  width: 100%;
  background: transparent !important;
  color: inherit !important;
  max-height: 100%;
  position: relative;
  display: block;

  &--hidden {
    overflow: hidden;
  }
}

.grid-wrapper {
  @extend .wrapper,
  .wrapper--hidden;
}

.date-setter__buttons {
  position: relative;
  padding: rem-calc(10);

  &.monthly {

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 1px;
      height: 7px;
      border-left: 1px solid rgba(black, 0.5);
      left: 50%;
      transform: translateX(-50%);
    }

    &:before {
      bottom: 0;
    }

    &:after {
      top: 0;
    }
  }
}

.slick-track {
  @include breakpoint(xs only) {
    display: flex;
  }
}

/* --------------------------------------------------
Map
-------------------------------------------------- */
#map {
  width: 100%;
  height: 60vh;
}

/* --------------------------------------------------
Parallax
-------------------------------------------------- */
.parallax {
  height: inherit;
  min-height: 60vh;
  width: inherit;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;

  @include breakpoint(xl) {
    background-position: center center;
    background-attachment: fixed;
  }
}


/* --------------------------------------------------
Aspect Ratio
-------------------------------------------------- */
%ratio {
  @include mk-ratio();

  & %ratio__container,
  & %ratio__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  & %ratio__bg {
    object-fit: cover;
    background-position: center center;
    background-size: cover;
  }

  &--square {
    @include mk-ratio('square');
  }

  &--circle {
    @include mk-ratio('square');
    border-radius: 100%;
  }

  &--portrait {
    @include mk-ratio('portrait');
  }

  &--wide {
    @include mk-ratio('wide');
  }
}

.remove-ratio {
  & .media-wrapper {
    height: 100%;
  }
}

.hoverFadeIn {
  opacity: 0.5;
  transition: all 0.2s;

  &:hover {
    opacity: 1;
  }
}

.hidden {
  display: none;
}

.shrink {
  height: auto;
  max-height: 100vh;
  overflow: hidden;
  transition: all 0.5s;

  &.in {
    max-height: 0;
  }
}

.defaultIconClass {
  opacity: 0.45;
}

.expand-icon.expanded {
  transform: rotate(180deg);
}



.quote {
  @include mk-flexbox(row, $align: center, $justify: flex-start);
  background: rgba(0, 0, 0, 0.02);
  position: relative;
  margin-bottom: rem-calc(16);

  & .content {
    padding: rem-calc(5);
    font-size: 90%;
    z-index: 1;
    font-style: italic;
  }

  & .icon {
    z-index: 0;
    width: rem-calc(48);
    height: rem-calc(48);
    opacity: 0.15;
    position: relative;
    float: left;

    &.open {
      top: 0;
      left: 0;
      transform: rotate(180deg);
    }

  }
}

:disabled,
.disabled {
  cursor: not-allowed;
  pointer-events: none;
  color: $medium-gray !important;
}


.fullScreen {

  &.on {
    z-index: 99999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: rgba(black, .75);

    & .toggleFS {
      @extend .fa-times;
    }
  }

  & .toggleFS {
    display: block !important;
  }
}

.toggleFS {
  @extend .far,
  .fa-expand;
  cursor: pointer;
  position: absolute;
  top: rem-calc($gutter-base/2);
  right: rem-calc($gutter-base/2);
  opacity: .4;
  z-index: 1;
  display: none !important;
}

@include breakpoint(md down) {
  [data-aos-delay] {
    transition-delay: 0s !important;
  }
}


.soft {
  background-color: $light-gray;
  box-shadow: 6px 6px 18px rgba(darken($color: $light-gray, $amount: 15), .6),
    -6px -6px 18px rgba(lighten($color: $light-gray, $amount: 15), .5);
  border-radius: rem-calc(4);
}

/* --------------------------------------------------
Scroll Wrapper

The scroll-content gets the max-height of the 
scroll-wrapper minus scroll-header
-------------------------------------------------- */
.scroll-wrapper {
  overflow: hidden !important;
  padding: 0 !important;
}

.scroll-content {
  position: relative;
  max-width: none !important;
  overflow: auto;
  height: 100%;
}

.scroll-header {
  @include mk-shadow(2, 'soft');
  margin: 0;
}

.draggable {
  &--vertical {
    padding-left: rem-calc($gutter-base);

    .grip {
      height: 100%;
      width: rem-calc($gutter-base/2);

      &:before {
        content: fa-content($fa-var-grip-vertical);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &--horizontal {
    .grip {
      width: 100%;
      height: rem-calc($gutter-base/2);

      &:before {
        content: fa-content($fa-var-grip-horizontal);
      }
    }

    &--top {
      padding-top: rem-calc($gutter-base);
    }

    &--bottom {
      padding-top: rem-calc($gutter-base);

      .grip {
        top: auto;
        bottom: 0;
      }
    }

  }
}

.grip {
  margin: 0 !important;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  font-size: rem-calc(16);
  cursor: drag !important;

  &:before {
    @extend %fa-icon;
    @extend .far;
    position: absolute;
    color: get-color(light-gray);
  }
}