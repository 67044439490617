@function strip-unit($number) {
  @if type-of($number)=='number'and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@function font-weight($var) {
  @return map-get($font-weights, $var);
}

@mixin mk-flexbox($flow: column,
  $align: stretch,
  $justify: flex-start,
  $grid: false,
  $width: auto) {
  display: flex;
  flex-flow: $flow;
  align-items: $align;
  justify-content: $justify;
  position: relative;
  width: $width;

  @if $flow==row {
    flex-wrap: wrap;
  }



  /*
    If $grid is set true, it sets the max-width to $global-width previously set in _settings.scss
    and use it to create the lateral padding when the resize gets closer to the grid. 
    */
  @if $grid==true {
    max-width: $global-width;

    @media all and (max-width: $global-width + ($global-width-padding * 2)) {
      padding-left: $global-width-padding/2;
      padding-right: $global-width-padding/2;
    }
  }
}

@mixin mk-gutter($Xgutter: margin,
  $Xvalue: 5,
  $Xtype: rem,
  $Ygutter: margin,
  $Yvalue: 0,
  $Ytype: rem) {
  @if $Xtype=='%' {
    $Xvalue: percentage($Xvalue/100);
  }

  @else {
    $Xvalue: rem-calc($Xvalue);
  }

  @if $Ytype=='%' {
    $Yvalue: percentage($Yvalue/100);
  }

  @else {
    $Yvalue: rem-calc($Yvalue);
  }

  #{$Xgutter}-left: $Xvalue;
  #{$Xgutter}-right: $Xvalue;
  #{$Ygutter}-top: $Yvalue;
  #{$Ygutter}-bottom: $Yvalue;
}

@mixin mk-gutter-xy($gutter: margin, $value: 5, $type: rem) {
  @if $type=='%' {
    $value: percentage($value/100);
  }

  @else {
    $value: rem-calc($value);
  }

  #{$gutter}-left: $value;
  #{$gutter}-right: $value;
  #{$gutter}-top: $value;
  #{$gutter}-bottom: $value;
}

@mixin mk-color-contrast($color, $pct, $force-color-white: false) {
  $bg-color: smart-scale($color, $pct);
  background-color: $bg-color;

  @if $force-color-white==true {
    color: white;
  }

  @else {
    $text-pct: $pct + 20;
    color: smart-scale($bg-color, $text-pct);
  }
}

/*
Mixin to create a color palette to element, text and _title
If $class-name is set, it creates a class with all palette
scheme
*/
@mixin mk-element-palette($color,
  $class-name: '',

  $add-pct: 20,
  $white-text: false) {
  $txt-color: black;
  $bg-color: smart-scale($color, $add-pct);

  @if $white-text==true {
    $txt-color: white;
  }

  @else {
    $txt-pct: $add-pct + 35;

    @if $txt-pct>100 {
      $txt-pct: 100;
    }

    $txt-color: color-pick-contrast($bg-color, ($white, $black), $tolerance:0);
  }

  @if $class-name !='' {
    .#{$class-name} {
      background-color: $bg-color;
      color: $txt-color;

      & #{$class-name}__title {
        color: lighten($txt-color, 20);
      }
    }
  }

  @else {
    background-color: $bg-color;
    color: $txt-color;

    & #{$class-name}__title {
      color: lighten($txt-color, 20);
    }
  }
}

/* ----------------------------------------------------------------------------
Gradient
-------------------------------------------------------------------------------
Just a prototype for speed gradient creation without 
having to visit colorzilla 
*/
@mixin mk-gradient($baseColor: black,
  $iniColor: '',
  $endColor: '',
  $iniTransp: 1,
  $endTransp: 1,
  $angle: 90) {
  @if ($iniColor=='') {
    $iniColor: $baseColor;
  }

  @if ($endColor=='') {
    $endColor: $baseColor;
  }

  background-color: $baseColor;
  background-image: -moz-linear-gradient($angle + deg,
    rgba($iniColor, $iniTransp) 0%,
    rgba($endColor, $endTransp) 100%);
  /* FF3.6-15 */
  background-image: -webkit-linear-gradient($angle + deg,
    rgba($iniColor, $iniTransp) 0%,
    rgba($endColor, $endTransp) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background-image: linear-gradient($angle + deg,
    rgba($iniColor, $iniTransp) 0%,
    rgba($endColor, $endTransp) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$iniColor, endColorstr=$endColor, GradientType=0);
  /* IE6-9 */
}

/* ----------------------------------------------------------------------------
Before Background
-------------------------------------------------------------------------------
Snippet to create a pseudo-element :before as a filter
Usually used to hover photos
*/
@mixin add-filter($bg-color: black,
  $opacity: 1,
  $opacity-hover: $opacity,
  $position: before,
  $iniColor: '',
  $endColor: '',
  $iniTransp: 1,
  $endTransp: 1,
  $angle: 90) {
  &:#{$position} {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    // background: $bg-color;
    @include mk-gradient($bg-color, $iniColor, $endColor, $iniTransp, $endTransp, $angle);
    opacity: $opacity;
    transition: all 0.4s;
  }

  &:hover:#{$position} {
    opacity: $opacity-hover;
  }

}

/* ----------------------------------------------------------------------------
Ratio
-------------------------------------------------------------------------------
Creates aspect ratio by adding pseudo elements :before and :after

*/
@mixin mk-ratio($ratio: '4x3') {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;

  $ratios: ('4x3': 75%,
      'square':100%,
      'wide': 9/16 * 100%,
      'portrait': 4/3 * 100%,
    );

  &:before,
  &:after {
    content: '';
    display: table;
    width: 1px;
    margin-left: -1px;
  }

  &:before {
    padding-bottom: map-get($ratios, $ratio);
    float: left;
    clear: both;
  }
}

/* ----------------------------------------------------------------------------
Spacing
-------------------------------------------------------------------------------
Create spacing to be used on flexboxes to set a gutter between children items

*/

@mixin mk-spacing() {
  @each $gutter in $gutters {
    .grid__container.spacing-#{$gutter} {
      width: auto;
      margin: rem-calc(-$gutter/2);

      &>.grid__item {
        padding: rem-calc($gutter/2);
      }
    }

  }

  @each $gutter in $gutters {

    @each $name,
    $size in $breakpoints {
      @include breakpoint($name) {
        .grid__container.spacing-#{$name}-#{$gutter} {
          margin: rem-calc(-$gutter/2);

          &>.grid__item {
            padding: rem-calc($gutter/2);
          }
        }
      }
    }
  }

}

@mixin mk-generic-margin-padding() {
  $items: (m:margin,
      mt:margin-top,
      mr:margin-right,
      mb:margin-bottom,
      ml:margin-left,
      p:padding,
      pt:padding-top,
      pr:padding-right,
      pb:padding-bottom,
      pl:padding-left,
    );


  @each $k,
  $v in $items {
    .#{$k}-auto {
      #{$v}: auto;
    }

    .#{$k}-0 {
      #{$v}: 0;
    }

    @each $gutter in $gutters {
      .#{$k}-#{$gutter} {
        #{$v}: rem-calc($gutter);
      }
    }

  }
}




/* ----------------------------------------------------------------------------
Component palette
-------------------------------------------------------------------------------
Mixin focused on buttons and states

Creates component palette with states :hover, :focus and :active adding
classes .flat, .raised, .floater, .outlined and .disabled

*/
@mixin mk-component-palette($component) {

  @each $name,
  $color in $foundation-palette {
    $hover-color: smart-scale($color, 5%);

    #{$component}--#{$name} {
      color: $color;
      background-color: transparent;
      border: 1px solid rgba($color, 0);
      transition: background-color .3s, color .3s, border-color .3s;

      &:hover,
      &:focus,
      &:active {
        color: $color;
        background-color: scale-color($color, $lightness:85%);
      }

    }

    #{$component}--#{$name}--link,
    #{$component}--#{$name}.link {
      text-align: left;
      font-size: inherit;
      padding: rem-calc(2);
      border: none;
      border-bottom: 1px solid transparent;
      border-radius: 0;

      &:hover,
      &:focus,
      &:active {
        border-bottom-color: $color;
        color: scale-color($color, $lightness:-15%);
        background-color: transparent;
      }
    }

    #{$component}--#{$name}--light,
    #{$component}--#{$name}.light {
      color: scale-color($color, $lightness:-40%);
      background-color: scale-color($color, $lightness:65%);

      &:hover,
      &:focus,
      &:active {
        color: scale-color($color, $lightness:-60%);
        background-color: scale-color($color, $lightness:55%);
      }

    }

    #{$component}--#{$name}--flat,
    #{$component}--#{$name}.flat,
    #{$component}--#{$name}--raised,
    #{$component}--#{$name}.raised,
    #{$component}--#{$name}--floater,
    #{$component}--#{$name}.floater {
      background-color: $color;
      color: color-pick-contrast($color, ($white, $black), $tolerance:2);
      border-color: $color;

      &:hover,
      &:focus,
      &:active {
        border-color: $hover-color;
        background-color: $hover-color;
        color: color-pick-contrast($hover-color, ($white, $black), $tolerance:2);
        border-color: $hover-color;
      }

    }

    #{$component}--#{$name}--raised,
    #{$component}--#{$name}.raised {
      @include mk-elevation(3, $shadow-color:$color);
    }

    #{$component}--#{$name}--floater,
    #{$component}--#{$name}.floater {
      @include mk-elevation(4, 'soft', $shadow-color:$color);
    }

    #{$component}--#{$name}--outlined,
    #{$component}--#{$name}.outlined {
      background-color: transparent;
      color: $color;
      border-color: $color;

      &:hover,
      &:focus,
      &:active {
        background-color: rgba($color, .1);
      }
    }

    #{$component}--#{$name}--squared,
    #{$component}--#{$name}.squared {
      border-radius: 0;
    }

    #{$component}--#{$name}--gradient,
    #{$component}--#{$name}.gradient {
      overflow: hidden;
      border-width: 0;

      &:before {
        content: '';
        background-color: $color;
        position: absolute;
        border-radius: inherit;
        width: 101%;
        height: 101%;
        left: 0;
        top: 0;
        z-index: -1;
        @include mk-gradient(darken($color, 15),
          $endColor: lighten($color, 15),
          $angle: -25);
      }

      & * {
        color: color-pick-contrast($base:$color, $tolerance:2);
        border-color: rgba(color-pick-contrast($base:$color, $tolerance:2), 0.5);
      }

      & .text-mutted {
        opacity: .54;
      }
    }
  }


  #{$component} {

    &:disabled,
    &.disabled {
      color: darken(get-color(medium-gray), 10%) !important;
      background: transparent;
      background-color: lighten($light-gray, 10%) !important;
      border-color: lighten($light-gray, 5%) !important;
      box-shadow: none !important;
    }
  }

}


/* ----------------------------------------------------------------------------
Gradient palette
-------------------------------------------------------------------------------
Adds a .bg--{color} adding a gradient

*/
@mixin mk-palette-gradients() {

  @each $name,
  $color in $foundation-palette {

    .bg--#{$name} {
      background-color: $color;
      overflow: hidden;

      &.gradient {
        background-color: transparent;

        &:before {
          content: '';
          background-color: $color;
          position: absolute;
          border-radius: inherit;
          width: 101%;
          height: 101%;
          left: 0;
          top: 0;
          z-index: -1;
          @include mk-gradient(darken($color, 15),
            $endColor: lighten($color, 15),
            $angle: -25);
        }
      }

      & * {
        color: color-pick-contrast($color, (lighten($color, 85%), darken($color, 85%)), $tolerance:0);
        border-color: rgba(color-pick-contrast($color, (lighten($color, 85%), darken($color, 85%)), $tolerance:0), 0.5);
      }

      & .text-mutted {
        opacity: .54;
      }
    }
  }
}


/* ----------------------------------------------------------------------------
Opacity
-------------------------------------------------------------------------------
Create opacity classes from 0 to 95 with interval of 5

*/
@mixin mk-opacity-classes() {
  $max: 95;
  $interval: 5;

  @for $i from 0 through $max {
    .op-#{$i} {
      opacity: $i/100;
    }

    $i : $i+$interval;
  }
}

@include mk-opacity-classes();



/* ----------------------------------------------------------------------------
Shadow
-------------------------------------------------------------------------------
Create smooth shadows 

*/

@function get-shadow($color:#000, $x:0, $y:0, $blur:0, $spread:0, $opacity:0, $position:null) {
  $result: $x+'px '+$y+'px '+$blur+'px '+$spread+'px '+rgba($color, $opacity);

  @if $position {
    $result: $position +' '+ $result;
  }

  @return unquote($result);
}

@mixin mk-shadow($level:1, $type:'default', $color:#000) {
  $shadow: none;

  @if $level>=1 {
    $shadow-types: ('default':(x:0, y:0.5, blur:1, spread:0, opacity:0.17, opacity-function:'minus-level'),
        'long':(x:0, y:2, blur:2, spread:0, opacity:0.09),
        'difuse':(x:0, y:1, blur:2, spread:0, opacity:0.03, opacity-function:'increase', opacity-function-param:0.04),
        'soft':(x:0, y:1, blur:2, spread:0, opacity:0.07),
        'softer':(x:0, y:1, blur:3, spread:0, opacity:.025),
        'sharp':(x:0, y:1, blur:2, spread:0, opacity:0.05, opacity-function:'decrease', opacity-function-param:0.05),
        'inner':(x:0, y:1, blur:3, spread:0, opacity:0.08, position:inset),
        'outline':(x:0, y:0, blur:0, spread:1, opacity:0.06),
      );

    $color: darken($color, 10%);
    $shadow-type: map-get($shadow-types, $type);
    $x: map-get($shadow-type, x);
    $y: map-get($shadow-type, y);
    $blur: map-get($shadow-type, blur);
    $spread: map-get($shadow-type, spread);
    $opacity: map-get($shadow-type, opacity);
    $opacity-increase: map-get($shadow-type, opacity-increase);
    $opacity-decrease: map-get($shadow-type, opacity-decrease);
    $position: map-get($shadow-type, position);

    $multiplier: 1;
    $shadow: get-shadow($color, $x, $y, $blur, $spread, $opacity, $position);

    @if $level>1 {
      @if variable-exists(opacity-function) {
        @if $opacity-function=='decrease' {
          $opacity: $opacity*$level;
        }
      }

      @for $i from 2 through $level {
        $multiplier: $multiplier*2;

        @if variable-exists(opacity-function) {
          @if $opacity-function=='increase' {
            $opacity: $opacity + $opacity-function-param;
          }

          @if $opacity-function=='decrease' {
            $opacity: $opacity - $opacity-function-param;
          }

          @if $opacity-function=='minus-level' {
            $opacity: $opacity - ($level/100);
          }
        }

        $shadow: $shadow+', '+get-shadow($color, $x*$multiplier, $y*$multiplier, $blur*$multiplier, $spread*$multiplier, $opacity, $position);
      }
    }
  }

  box-shadow: $shadow;

}

/* ----------------------------------------------------------------------------
Elevation
-------------------------------------------------------------------------------
Create elevation effect using mk-shadow and scale3d

*/
@mixin mk-elevation($level:0, $type:'default', $speed:.3, $shadow-color:#000) {
  transition: all unquote($speed+'s') ease-in-out;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  z-index: 1;
  transform: scale3d(1, 1, 1);
  @include mk-shadow($level, $type, $shadow-color);

  &:hover,
  &:focus {
    z-index: $level+1;
    transform: scale3d(1+$level*.005, 1+$level*.005, 1+$level*.005);

    @include mk-shadow($level+1, $type, $shadow-color);
  }

  &:active,
  &:active:focus {
    transform: scale3d(1+$level*.0025, 1+$level*.0025, 1+$level*.0025);
    transition: all unquote($level*.025+'s') ease-out;

    @if $level>0 {
      $level: $level - 1;
    }

    @include mk-shadow($level, $type, $shadow-color);
  }
}