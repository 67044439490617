.media-wrapper {
  background: transparent;
  backface-visibility: hidden;
  overflow: hidden;
  margin: 0;
  display: block;

  @extend %ratio;

  &--square {
    @extend %ratio--square;
  }

  &--portrait {
    @extend %ratio--portrait;
  }

  &--wide {
    @extend %ratio--wide;
  }

  &--circle {
    @extend %ratio--circle;
  }

  & .media,
  &>img {
    @extend %ratio__bg;
  }

  &--contain {

    .media {
      object-fit: contain !important;
      max-width: 100% !important;
      height: 100% !important;
    }
  }



  &>img {
    transition: transform 10s linear;
    max-width: none;
    backface-visibility: hidden;
  }

  &.zoom:hover {
    .media {
      transform: scale(1.2);
    }
  }
}

.media {
  transition: transform 10s linear;
  max-width: none;
  backface-visibility: hidden;
}

.media-wrapper__footer {
  position: absolute;
  bottom: 0;

  width: 100%;

  background: rgba(white, 0.2);

  color: $body-font-color;
}

.media-caption {
  @extend .caption;
  padding: rem-calc($gutter-base/2);
  background-color: rgba(black, .02);
}