@import './foundation-settings';

// ---------
// Global
// ---------

$global-font-size: 100%;
$global-width: rem-calc(768);
$global-width-padding: rem-calc($global-width * 0.03);

$body-font-family-base: -apple-system,
BlinkMacSystemFont,
'Segoe UI',
'Roboto',
'Oxygen',
'Ubuntu',
'Cantarell',
'Fira Sans',
'Droid Sans',
'Helvetica Neue',
sans-serif;
$body-font-family: 'Open Sans',
$body-font-family-base;

// --------
// Header
// --------
$topbar-height: rem-calc(48);

// --------
// Palette
// --------

// Black & White Scale
$black: #1a1a1a;
$white: #fefefe;
$dark-gray: lighten($black, 15%);
$medium-gray: lighten($black, 45%);
$light-gray: lighten($black, 70%);
$super-light-gray: darken($white, 2%);

// Text
$text-primary: #333333;
$text-white: rgb(223, 223, 223);
$text-secondary: rgba(black, 0.35);
$text-emphasis: #222;

// Color palette
$foundation-palette: (primary: #2D9CDB,
    secondary: #B132FF,
    tertiary: #2D3E52,
    success: #68ca66,
    warning: #ffd249,
    red: #a4260a,
    alert: #ea5b3a,
    error: #ea5b3a,
    info: #56ccf2,
    white: $white,
    black: $black,
    gold: #ffce2d,
    silver: #cfcfcf,
    platinum: #b6b6b6,
    diamond: #00ADEE,
    amber: #e08108,
    purple: #7808e0,
    lilac: #ae08e0,
    pink: #E8505B,
    dark-gray:$dark-gray,
    medium-gray:$medium-gray,
    light-gray:$light-gray,
    super-light-gray:$super-light-gray,
    text-primary:$text-primary,
    text-white:$text-white,
    text-secondary:$text-secondary,
    text-emphasis:$text-emphasis,
);
@include add-foundation-colors;

$font-weights: (light:300,
    regular:400,
    medium:600,
    bold:900,
);


// Breakpoints
// --------------

// Material Design
// xs, extra-small: 0px or larger
// sm, small: 600px or larger
// md, medium: 960px or larger
// lg, large: 1280px or larger
// xl, extra-large: 1920px or larger
$breakpoints: (small: 0,
    medium: 640px,
    large: 1024px,
    xlarge: 1200px,
    xxlarge: 1440px,
    xs: 1px,
    sm: 600px,
    md: 960px,
    lg: 1280px,
    xl: 1920px);
$print-breakpoint: large;
$breakpoint-classes: (small medium large xlarge xxlarge xs sm md lg xl);


// Gutters
// -----------
$gutters: (8, 16, 24, 32, 40, 48, 56, 64);
$gutter-base: rem-calc(32);