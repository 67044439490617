@import '~antd/dist/antd.css';
@import './assets/acms-bootstrap/scss/acms-bootstrap.scss';

body {
    background-color: #FCFCFC;
}

// ----------
// Panel
// ----------
.panel {
    &__header {
        margin: 0 rem-calc(16);
        padding: rem-calc(16) 0;
    }

    &__body {
        padding: rem-calc(16);
    }

}

// ----------
// Ant Design
// ----------


[class^='ant-'] {

    .ant-tooltip-inner {
        border-radius: rem-calc(50);
        font-weight: 600;
        padding-left: rem-calc(10);
        padding-right: rem-calc(10);
    }

    .ant-radio-wrapper-checked {
        font-weight: 600;
    }

    .ant-radio-wrapper:not(:last-child) {
        margin-bottom: rem-calc(8);
    }

    &.ant-slider-with-marks {
        margin-bottom: rem-calc(16);
    }

    .ant-slider-mark-text {
        font-size: rem-calc(12);
    }

    &.primary {
        .ant-radio-inner {
            border-color: get-color(primary);

            &:after {
                background-color: get-color(primary);
            }
        }

        .ant-slider-handle,
        .ant-slider-dot-active {
            border-color: get-color(primary);
        }

        .ant-slider-track {
            background-color: get-color(primary);
        }

        .ant-slider-handle:focus {
            box-shadow: 0 0 0 5px rgba(get-color(primary), .12)
        }
    }

    &.secondary {
        .ant-radio-inner {
            border-color: get-color(secondary);

            &:after {
                background-color: get-color(secondary);

            }
        }

        .ant-slider-handle,
        .ant-slider-dot-active {
            border-color: get-color(secondary);
        }

        .ant-slider-track {
            background-color: get-color(secondary);
        }

        .ant-slider-handle:focus {
            box-shadow: 0 0 0 5px rgba(get-color(secondary), .12)
        }
    }


}


#styled-tooltip {
    &.primary {

        .ant-tooltip-inner,
        .ant-tooltip-arrow-content {
            background-color: get-color(primary);
        }
    }

    &.secondary {

        .ant-tooltip-inner,
        .ant-tooltip-arrow-content {
            background-color: get-color(secondary);
        }
    }
}


// ----------
// Form
// ----------
.big-input {
    font-weight: 700 !important;
    font-size: rem-calc(26) !important;

    &.primary {
        color: get-color(primary) !important;
    }

    &.secondary {
        color: get-color(secondary) !important;
    }
}

// ----------
// List
// ----------
.list-item {
    transition: .3s background-color;

    &:not(:last-child) {
        border-bottom: 1px solid rgba(black, .05);
    }

    &:hover {
        background-color: rgba(black, .05);
    }
}

// .vert-divisor:before {
//     position: absolute;
//     height: 100%;
//     left: 50%;
//     top: 50%;
//     transform: translate(-50%, -50%);
//     border-left: 1px solid rgba(black, .02);
// }