@mixin mk-text-palette() {

  @each $key,
  $val in $foundation-palette {

    .--t-#{$key},
    .--t-#{$key} * {
      color: $val !important;
    }
  }
}

@include mk-text-palette();

// --- Text Align
[class*='--ta-center'] {
  text-align: center !important;
}

[class*='--ta-left'] {
  text-align: left !important;
}

[class*='--ta-right'] {
  text-align: right !important;
}

[class*='--ta-justify'] {
  text-align: justify !important;
}

// --- Text Transform
[class*='--upper'] {
  text-transform: uppercase;
}

[class*='--nocase'] {
  text-transform: none;
}

[class*='--t-zero'] {
  font-size: 0 !important;
}

// --- Text Color
[class*='--t-initial'] {
  color: initial !important;
}

[class*='--t-white'] {
  color: white !important;
}

[class*='--t-primary'] {
  color: get-color(primary) !important;
}

[class*='--t-secondary'] {
  color: get-color(secondary) !important;
}

[class*='--t-danger'] {
  color: get-color(alert) !important;
}

[class*='--t-warning'] {
  color: get-color(warning) !important;
}

[class*='--t-success'] {
  color: get-color(success) !important;
}

[class*='--t-dark-gray'] {
  color: $dark-gray;
}

[class*='--t-bold'] {
  font-weight: 600;
}

[class*='--t-bolder'] {
  font-weight: 700;
}

[class*='--t-black'] {
  font-weight: 900;
}

[class*='--title'] {
  font-size: rem-calc(20);
  font-weight: 600;
  margin-bottom: 0;
}

[class*='--subheading'] {
  font-size: rem-calc(18);
  font-weight: 400;
  margin-bottom: 0;
}