.btn {
  display: inline-block;
  position: relative;
  // @include mk-flexbox(row, $align:center, $justify:center);
  padding: rem-calc(10) rem-calc(14);
  vertical-align: middle;
  text-align: center;
  line-height: 1.4;
  text-transform: none;
  letter-spacing: normal;
  text-decoration: none;
  box-shadow: none;
  height: auto;
  background-color: transparent;

  cursor: pointer;

  color: inherit;
  // font-family: $body-font-family;
  font-size: rem-calc(15);
  font-weight: 600;

  // background-color: white;

  border-radius: rem-calc(3);
  border-width: 0;
  // border-color: $medium-gray;
  // border-style: solid;
  // border-width: 1px;

  transition: all 0.2s ease-in-out;

  // &:hover:not(.is-active) {
  //   box-shadow: inset 0 0 1000px rgba(black, 0.1);
  // }

  & label {
    cursor: inherit;
  }

  &--small,
  .small {
    padding: rem-calc(6) rem-calc(12);
    font-size: rem-calc(13);
  }

  &--big,
  .big {
    padding: rem-calc(14) rem-calc(24);
    font-size: rem-calc(17);
  }

  &--pill,
  .pill {
    border-radius: rem-calc(100) !important;
  }

  &--squared,
  .squared {
    border-radius: 0;
  }
}

$icon-btn-size: 44;

.icon-btn {
  @include mk-flexbox(row, $align:center, $justify:center);
  padding: 0;
  border-radius: rem-calc(100);
  width: rem-calc($icon-btn-size);
  height: rem-calc($icon-btn-size);

  &.btn--small,
  &.small {
    width: rem-calc($icon-btn-size - 8);
    height: rem-calc($icon-btn-size - 8);
  }

  &.btn--big,
  &.big {
    width: rem-calc($icon-btn-size + 8);
    height: rem-calc($icon-btn-size + 8);
  }
}

@include mk-component-palette('.btn');
@include mk-component-palette('.acms-admin a.btn');


.btn {
  &--vertical {
    flex-direction: column;
  }

  &--vertical-center {
    flex-direction: column;
    justify-content: center;
  }

  &--radio {
    @extend .btn--primary,
    .outlined;
    position: relative;
    width: 100%;
  }

  &--image {
    padding: 0;

    img {
      border-radius: inherit;
    }

    &.disabled,
    &:disabled {
      img {
        opacity: .3;
      }
    }
  }
}

.btn__content {
  @include mk-flexbox();
  color: inherit;
  line-height: 1.4;
}

.btn__caption {
  @extend .caption;
  color: inherit !important;
  cursor: pointer;

  &.text-mutted {
    color: $medium-gray !important;
    opacity: 0.6;
  }
}

.btn__text {
  color: inherit;
}


.tabs__button {
  color: get-color(primary) !important;

  &--selected {
    color: $medium-gray !important;
  }
}

.tabs__indicator {
  background-color: get-color(primary) !important;
  height: 1px !important;
}

.close-btn {
  @extend .fas,
  .fa-times;
  cursor: pointer;

  position: absolute;
  top: 0;
  right: 0;

  font-size: rem-calc(18);

  text-align: center;
  line-height: rem-calc(48);
  height: rem-calc(48);
  width: rem-calc(48);
  z-index: 1;
}

.edit-btn {
  @extend .btn,
  .btn--primary,
  .outlined,
  .fas,
  .fa-edit;
  cursor: pointer;

  text-align: center;
  font-size: rem-calc(12);

  span {
    margin: rem-calc(5);
    font-family: $body-font-family;
  }
}

.action-btns {
  @include mk-flexbox(row, $justify:flex-end, $align:center);

  & .action-btn {
    margin: rem-calc(4)
  }
}